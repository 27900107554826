$color-purple: #1F2937;
$color-pink: #EC4899;
$color-gray: #9CA3AF;
$color-black: #1F2937;
$card-size: 23rem;

* {
  box-sizing: border-box;
}

.carousel {
	display: flex;
	flex-direction: row;
	margin: 0 auto;
	position: relative;
	width: 100%;
	max-width: 100%;
	height: 100%;
	min-height: $card-size;
	perspective: 1000px;
	transform-style: preserve-3d;
	align-items: center;
	justify-content: center;
}

.card-container {
  min-height: 100%;
  position: absolute;
  max-width: 100%;
  display: flex;
  align-items: center;
  transform: 
    rotateY(calc(var(--offset) * 50deg)) 
    scaleY(calc(1 + var(--abs-offset) * -0.4))
    translateZ(calc(var(--abs-offset) * -30rem))
    translateX(calc(var(--direction) * -5rem));
  filter: blur(calc(var(--abs-offset) * 1rem));
  transition: all 0.35s ease-out;
}

.card {
  width: 100%;
  height: 100%;
  padding: 2rem;
  background-color: hsl(0deg, 70%, calc(100% - var(--abs-offset) * 50%));
  border-radius: 1rem;
  color: $color-black;
  text-align: justify;
  transition: all 0.3s ease-out;
  
  h2 {
    text-align: center;
    font-size: 2rem;
    font-weight: bold;
    margin: 0 0 0.7em;
    color: $color-black;
  }
  
  p, h2 {
    transition: all 0.3s ease-out;
    opacity: var(--active);
  }
}

.card_class{
	//Set to white if --active is 1
	background-color: hsla(1deg, 70%, calc(100% - var(--abs-offset) * 50%), var(--active));
	//background-opacity: var(--active);
}

.nav {
  color: black;
  font-size: 5rem;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 50%;
  z-index: 2;
  cursor: pointer;
  user-select: none;
  background: unset;
  border: unset;
  
  &.left {
    transform: translateX(-100%) translatey(-50%);
  }
  
  &.right {
    right: 0;
    transform: translateX(100%) translatey(-50%);
  }
}