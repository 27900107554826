$tf-red: #EF3737;

.App {
    background: white;
    text-align: center;
	height: 100%;
}
  
  .App-logo {
    height: 40vmin;
    pointer-events: none;
  }
  
  @media (prefers-reduced-motion: no-preference) {
    .App-logo {
      animation: App-logo-spin infinite 20s linear;
    }
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  .loading-spinner{
    display: flex;
    height: 100%;
    width: 100%;
    justify-content: center;
    align-items: center;
    margin-top: 8rem;
    margin-bottom: 8rem;
  }
  
  .loader {
    width: 200px;
    height: 140px;
    background: #979794;
    box-sizing: border-box;
    position: relative;
    border-radius:8px;
    perspective: 1000px;
  }

  .loader:before{
    content: '';
    position: absolute;
    left: 10px;
    right: 10px;
    top: 10px;
    bottom: 10px;
    border-radius:8px;
    background: #f5f5f5  no-repeat;
    background-size: 60px 10px;
    background-image: 	linear-gradient(#ddd 100px, transparent 0) ,
              linear-gradient(#ddd 100px, transparent 0), 
              linear-gradient(#ddd 100px, transparent 0), 
              linear-gradient(#ddd 100px, transparent 0), 
              linear-gradient(#ddd 100px, transparent 0), 
              linear-gradient(#ddd 100px, transparent 0);
    
    background-position: 15px 30px , 15px 60px , 15px 90px, 
              105px 30px , 105px 60px , 105px 90px;
    box-shadow: 0 0 10px rgba(0,0,0,0.25);
  }
  .loader:after {
    content: '';
      position: absolute;
      width: calc(50% - 10px);
      right: 10px;
      top: 10px;
      bottom: 10px;
      border-radius: 8px;
      background: #fff no-repeat;
      background-size: 60px 10px;
      background-image: linear-gradient(#ddd 100px, transparent 0), 
              linear-gradient(#ddd 100px, transparent 0), 
              linear-gradient(#ddd 100px, transparent 0);
      background-position: 50% 30px ,50% 60px , 50%  90px;
      transform: rotateY(0deg );
      transform-origin: left center;
    animation: paging 1s linear infinite;
  }


  @keyframes paging {
    to {
      transform: rotateY( -180deg );
    }
  }
    
// $tf-red
// $color-gradient: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(242,242,242,0.950) 100%);
// $color-section-white-gradient: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(242,242,242,0.950) 100%);
// $color-component-section-bg: rgba(240,240,240,1);
// $color-placeholder-text: rgba(3,17,27,0.420);
// $color-red-gradient: linear-gradient(to bottom, rgba(255,255,255,1) 0%,rgba(236,28,36,0.925) 100%);
// $color-regular-text: rgba(53,56,61,1);
// $color-red-gradient-2: linear-gradient(to bottom, rgba(236,28,36,0.925) 0%,rgba(255,73,80,1) 100%);
// $color-section: rgba(0,0,0,1);
// $color-subheader: rgba(0,0,0,1);
// $color-h-2-component-sect: rgba(255,255,255,1);
// @mixin text-style-hero-heading-1() {
// 	font-size: 68px;
// 	font-family: "Lato";
// 	font-weight: 800;
// 	font-style: normal;
// 	text-decoration: none;
// 	text-transform: none;
// }
// @mixin text-style-header-section-main() {
// 	font-size: 52px;
// 	font-family: "Lato";
// 	font-weight: 700;
// 	font-style: normal;
// 	text-decoration: none;
// 	text-transform: none;
// }
// @mixin effect-style-shadow-md() {
// 	box-shadow: 0px 4px 4px rgba(0,0,0,0.400);
// }
  