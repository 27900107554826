.contact-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

.contact{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

.contact-form-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
    padding-top: 1rem;
    padding-bottom: 4rem;
    margin-top: 1.5rem;
    margin-bottom: 4rem;
}