.search-container {
    // Your styles for the main search container
    // Example: change background color

    // Styles for the sidebar
    .sidebar {
        // Example: change width
        width: 250px;
    }

    // Styles for the search results container
    .search-results {
        // Example: add a box shadow
    }
}

.search-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

.search{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

.search-page{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}
 
