$TF_RED: #AB4E52;

html{
	height: 100%;
}

body, #root{
    display: inline;
    margin: 0px;
    min-height: 100vh;
    overflow-y: scroll;
	transition: all 0.2s cubic-bezier(0.645, 0.045, 0.355, 1);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

// Custom scrollbar styles
::-webkit-scrollbar {
    width: 7px; // you can adjust this to change the thickness of the scrollbar
}

::-webkit-scrollbar-track {
    background: transparent; // this makes the background of the scrollbar track transparent
}

::-webkit-scrollbar-thumb {
    background: $TF_RED; // this is the color of the scroll thumb
    border-radius: 20px; // this makes the scroll thumb round
}

::-webkit-scrollbar-thumb:hover {
    cursor: pointer;
    background: #FFFFFF; // this changes the color of the scroll thumb when hovering
    border: 1px solid $TF_RED; // this adds a border to the scroll thumb
    border-radius: 20px; // this makes the scroll thumb round
}
  