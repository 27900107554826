.showroom-wrapper{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

.showroom{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    height: 100%;
    width: 100%;
}

.custom-autocomplete-list {
    position: absolute;
    bottom: -50px;
    width: 100%;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 12px rgba(0, 0, 0, 0.1);
    max-height: 300px;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0;
    margin: 0;
    list-style: none;
    text-align: left;
}


  