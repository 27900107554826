// Reviews.scss
.modal-content {
    &.submit-modal {
      .modal-header {
        background-color: #F7FAFC;
        border-bottom: 1px solid #EDF2F7;
        padding: 1rem;
      }
  
      .modal-body {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 2rem;
      }
  
      .rating-label {
        margin-bottom: 1rem;
      }
  
      .chakra-textarea {
        padding: 0.5rem;
        border: 1px solid #E2E8F0;
        border-radius: 0.375rem;
      }
  
      .submit-button {
        margin-top: 1.5rem;
      }
    }
}
   