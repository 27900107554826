.header {
  height: 70px;
  width: 100%;
  background-color: #F2F2F2;
  color: #000;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5%;
  position: relative;
  z-index: 1;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

#nav-buttons {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

}

.dropdown-menu {
  display: block;
  position: absolute;
  z-index: 1000;
}

.dropdown {
  position: absolute;
  z-index: 10;
  background-color: #fff;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}